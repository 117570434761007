const boxesData = {
    netflix: {
      imgUrl: './netflix.svg',
      caption: 'Netflix Landing Page',
      url: 'https://www.netflix.com/browse',
      flexboxText: 'Front End: Netflix landing Page built with HTML & CSS'
    },
    Edamam: {
      imgUrl: 'https://pmcdeadline2.files.wordpress.com/2017/08/hbo-logo.jpg?w=681&h=383&crop=1',
      caption: 'Recipe App',
      url: 'https://edamamrecipeapp.firebaseapp.com/',
      flexboxText: 'Full Stack JS: User inputs an ingredient and Edamam API responds with recipes'
    },

   Amingo: {
      imgUrl: 'https://media.gizmodo.co.uk/wp-content/uploads/2017/12/drda8fn0middpo6aabq0.jpg',
      caption: 'Amingo Social Media App',
      url: 'https://github.com/neilhendricks',
      flexboxText: 'Full Stack JS: Social Media site with front end/back capabilities'
    }
  
    
  }
  
  
  export default boxesData;