//import React, {useContext} from "react";
import React, { Component, useEffect } from "react";
import {withRouter} from "react-router"
import netflix from "./netflix.svg"
import EdamamIMG from "./EdamamIMG.png"
import Amingo from "./Amingo.png" 
import Box from "./Box/Box.js"
import boxesData from "./data.js"
import "./Skills&ProjectsCSS.scss"
//import $ from "jquery"



const SkillsandProjects = () => {
    useEffect(()=>{var entries = [ 
                
        { label: 'Javascript', url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript', target: '_blank' },
        { label: 'Node.js', url: 'https://developer.mozilla.org/en-US/docs/Learn/Server-side/Express_Nodejs/Introduction', target: '_blank' },
        { label: 'MVC', url: 'https://www.tutorialsteacher.com/mvc/mvc-architecture', target: '_blank' },
        { label: 'Heroku', url: 'https://www.heroku.com/what', target: '_blank' },
        { label: 'MVP', url: 'https://en.wikipedia.org/wiki/Minimum_viable_product', target: '_blank' },
        { label: 'PWA', url: 'https://hackernoon.com/everything-you-need-to-know-about-progressive-web-app-pwa-6524edbb0c57', target: '_blank' },
        { label: 'Microservices', url: 'https://www.redhat.com/en/topics/microservices/what-are-microservices', target: '_blank' },
        { label: 'React', url: 'https://reactjs.org/', target: '_blank' },
        { label: 'NoSQL', url: 'https://www.mongodb.com/nosql-explained', target: '_blank' },
        { label: 'Big O', url: 'https://rob-bell.net/2009/06/a-beginners-guide-to-big-o-notation/', target: '_blank' },
        { label: 'CSS', url: 'https://en.wikipedia.org/wiki/Cascading_Style_Sheets', target: '_blank' },
        { label: 'MongoDB', url: 'https://www.mongodb.com/', target: '_blank' },
        { label: 'HTML5', url: 'https://www.computerhope.com/jargon/h/html.htm', target: '_blank' },
        { label: 'npm', url: 'https://www.npmjs.com/', target: '_blank' },
        { label: 'WordPress', url: 'https://wordpress.com/', target: '_blank' },
        { label: 'es6', url: 'https://www.freecodecamp.org/news/write-less-do-more-with-javascript-es6-5fd4a8e50ee2/', target: '_blank' },
        { label: 'Express.js', url: 'https://developer.mozilla.org/en-US/docs/Learn/Server-side/Express_Nodejs/Introduction', target: '_blank' },
        { label: 'GitHub', url: 'https://github.com/neilhendricks', target: '_blank' },
        { label: 'Rspnsv Web Des', url: 'https://www.smashingmagazine.com/2011/01/guidelines-for-responsive-web-design/', target: '_blank' },
        { label: 'Webpack', url: 'https://webpack.js.org/', target: '_blank' },
        { label: "API's", url: 'https://www.mulesoft.com/resources/api/what-is-an-api', target: '_blank' },
        { label: 'Python', url: 'https://www.python.org/doc/essays/blurb/', target: '_blank' },
        { label: 'OWASP', url: 'https://owasp.org/', target: '_blank' },
        { label: 'Mongoose', url: 'https://mongoosejs.com/', target: '_blank' },
        { label: 'Git', url: 'https://git-scm.com/', target: '_blank' },
        { label: 'jQuery', url: 'https://jquery.com/', target: '_blank' },
        { label: 'SEO', url: 'https://moz.com/learn/seo/what-is-seo', target: '_blank' },
        { label: 'JSON', url: 'https://www.json.org/json-en.html', target: '_blank' },

    ];

    var settings = {
      textShadowColor: 'rgba(0, 0, 0, 0.75)',
        textShadowOffset: {width: -1, height: 1},
        textShadowRadius: 10,
        entries: entries,
        width: 480,
        height: 480,
        radius: '75%',
        radiusMin: 75,
        bgDraw: true,
        bgColor: 'black',
        opacityOver: 1.00,
        opacityOut: 0.05,
        opacitySpeed: 6,
        fov: 800,
        speed: .5,
        fontFamily: 'Oswald, Arial, sans-serif',
        fontSize: '15',
        fontColor: 'red',
        fontWeight: 'normal',//bold
        fontStyle: 'normal',//italic 
        fontStretch: 'normal',//wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
        fontToUpperCase: true,
        tooltipFontFamily: 'Oswald, Arial, sans-serif',
        tooltipFontSize: '11',
        tooltipFontColor: '#fff',
        tooltipFontWeight: 'normal',//bold
        tooltipFontStyle: 'normal',//italic 
        tooltipFontStretch: 'normal',//wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
        tooltipFontToUpperCase: false,
        tooltipTextAnchor: 'left',
        tooltipDiffX: 0,
        tooltipDiffY: 10
        
    };
    
    //var svg3DTagCloud = new SVG3DTagCloud( document.getElementById( 'holder'  ), settings );
    //*Need to put window. before the $ in order for jquery code to work
    window.$( '#holder' ).svg3DTagCloud( settings );},[])     
    return (
        <div className="main">
      <header>
        <h1 style={{lineHeight: 2}}>Neil's Skills and Projects</h1> <br/>
      </header>
      <div className="site-content">
      <div id='holder' text-align="center" align-items="center" className="sphere" ></div>
        <div className="content">

           <Box imgUrl={netflix} 
           url= "https://neilhendricks.github.io/Neil-Netflix-Landing-Page/"
           flexboxText={boxesData.netflix.flexboxText} 
           caption={boxesData.netflix.caption}/> 
            
            <Box imgUrl={EdamamIMG}
            url={boxesData.Edamam.url}
            flexboxText={boxesData.Edamam.flexboxText}
            caption={boxesData.Edamam.caption}/>

            <Box imgUrl={Amingo}
            url={boxesData.Amingo.url}
            flexboxText={boxesData.Amingo.flexboxText}
            caption={boxesData.Amingo.caption}/>

            {/*<Box {...boxesData.Amingo}/> */}

           {/* <Box {...boxesData.hbo}/>   */}
        </div>
      
      </div>

    </div>
        
    )
}


export default SkillsandProjects;
