//import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import './App.css';
import AboutCube from './AboutCube';
import SkillsandProjectsCube from "./SkillsandProjectsCube"
import ContactCube from './ContactCube';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SkillsandProjects from './SkillsandProjects';
import About from "./About-Neil-Hendricks"
import Contact from "./Contact-Neil-Hendricks"
import { Link } from 'react-router-dom'
import consoleText from "./SWE-Typing-Effect.js"
import "./SWE-Typing-Effect.css"



const Home = () => {
  useEffect(() => {
      consoleText(['Full Stack Software Engineer'], 'text',['white']);
  }); 

  return (
  <div className="Main">
      <h1 className= "header main-title">Neil Hendricks</h1> 
      <h1 className= "header" align="center" > 
        <div class='console-container'>
            <span id='text'/>
          <div class='console-underscore' id='console'>&#95;</div></div></h1>
      <div className="Cubes">
        <div className= "AboutCube">
          <Link to={"/About-Neil-Hendricks"} ><AboutCube /> </Link>
        </div>

        <div className= "SkillsandProjectsCube">
        <Link to={"/SkillsandProjects"} ><SkillsandProjectsCube /> </Link>
        </div>

        <div className= "ContactCube">
        <Link to={"/Contact-Neil-Hendricks"} ><ContactCube /> </Link>
        </div>
    </div>
    </div>
)}



function App() {

  // useEffect(( )=> {
  //   ReactGA.initialize(UA-180237345-1)
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [])

  return (
      
      <BrowserRouter> 
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/SkillsandProjects" exact component={SkillsandProjects}  />
          <Route path="/About-Neil-Hendricks" exact component= {About} />
          <Route path="/Contact-Neil-Hendricks" exact component= {Contact} />
        </Switch>
      </BrowserRouter>
       
  
  );
}

export default App;
