//import React, {useContext} from "react";
import React, { Component } from "react";
//import { ReactComponent as githubIcon} from "./githubIcon.svg"
import githubIcon from "./githubIcon.svg"
import linkedinIcon from "./Neil-linkedin-Image.svg"
import WAVES from 'vanta/dist/vanta.waves.min'
import VantaComponent from "./VantaComponent";
import * as THREE from "three"

class Contact extends React.Component {
  
    constructor() {
      super()
      this.vantaRef = React.createRef()
    }
    componentDidMount() {
      this.vantaEffect = WAVES({
        el: this.vantaRef.current,
        THREE
      })
    }
    componentWillUnmount() {
      if (this.vantaEffect) this.vantaEffect.destroy()
    }
    render() {
      return <div className="Contact" ref={this.vantaRef}> 
        <h1 style={{lineHeight: 2}} >
          Contact Neil Hendricks
        </h1>
        <h1 > Wanna get to know me? Have a project in need of a software engineer? Contact me through Linkedin and gain some insight into my work through my Github!</h1>

            <div className="ContactIcons">
                <div className="githubIcon mediaIcon" >
                  <a href="https://github.com/neilhendricks" target = "_blank">
                <img id="githubIcon" src={githubIcon} alt="Neil Hendricks github image link"/>;
                  </a>
                </div>
                <div className="linkedinIcon mediaIcon">
                  <a href="https://linkedin.com/in/neil-hendricks" target = "_blank">
                <img id="linkedinIcon" src={linkedinIcon} alt="Neil Hendricks linkedin image link" />
                </a> 
                </div>
            </div>
      </div>
    }
  
  
  }

export default Contact;