import React from 'react';
import ReactDOM from 'react-dom';
import { render } from "react-dom";
import AboutCube from "./AboutCube";
//import './index.css';
import App from './App';

//ReactDOM.render(<App />, document.getElementById('root'));

/*
const styles = {
  root: {
    fontFamily: "sans-serif",
    textAlign: "center",
    height: "100%",
  },
  container: {
    background: "#222",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  
};


);
*/
render(<App />, document.getElementById("root"));