// function([string1, string2],target id,[color1,color2])    

const consoleText = (words, id, colors, speed = 120) => {
  if (colors === undefined) colors = ['#fff'];
  var visible = true;
  var con = document.getElementById('console');
  if(!con){
    console.log('Please test yout container ID');
  }

  var letterCount = 1;
  var x = 1;
  var waiting = false;

  var target = document.getElementById(id)
  target.setAttribute('style', 'color:' + colors[0]);

  window.setInterval(function() {
    if (letterCount === 0 && waiting === false) {
      waiting = true;

      target.innerHTML = words[0].substring(0, letterCount)
      
      // this function do the word changing
      window.setTimeout(function() {
        var usedColor = colors.shift();
        colors.push(usedColor);
        var usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        target.setAttribute('style', 'color:' + colors[0])
        letterCount += x;
        waiting = false;
      }, 1000); //speed
      
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 3000) //speed before delete
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, speed); //typing speed

  window.setInterval(function() {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;
    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 400) //blinking speed
}

export default consoleText;