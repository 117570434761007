import React from 'react';
import './Box.scss';

const DEFAULT_FLEXBOX_TEXT = "Some fadebox text!" ;

const Box = ({ imgUrl, url, caption, flexboxText = DEFAULT_FLEXBOX_TEXT }) => {
  return (
    <div className="box">
      <a href={url} target="_blank">
        <img src={imgUrl}/>
      
      <div className="fadebox">
        <div className="fadebox-text">
          { flexboxText }
        </div>  
      </div>
      </a>
      <div className="caption">{caption}</div>
    </div>
  );

}

export default Box;