import React, { Component } from "react";
import CLOUDS from 'vanta/dist/vanta.clouds.min'
import VantaComponent from "./VantaComponent";
import * as THREE from "three"
import consoleText from "./SWE-Typing-Effect.js"
import "./SWE-Typing-Effect.css"
class About extends React.Component {
  
  constructor() {
    super()
    this.vantaRef = React.createRef()
  }
  componentDidMount() {
    this.vantaEffect = CLOUDS({
      el: this.vantaRef.current,
      THREE
    })
    //consoleText(['ABOUT NEIL HENDRICKS'], 'text',['black']);
  }
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }
  render() {
    return <div className="about" ref={this.vantaRef}>
      <h1>
      {/* <div class='console-container'>
            <span id='text'/>
          <div class='console-underscore' id='console'>&#95;</div></div> */}
          ABOUT NEIL HENDRICKS
      </h1>
      <h1 style={{lineHeight: 2}} >Hi I'm Neil! </h1> 
      <h1 >I'm a full stack MERN software engineer, currently pursuing a degree in 
         Computer Science
         at Hofstra University in New York.</h1><br />
      <h1>I'm also an Eagle Scout, and Founder and Assitant Scoutmaster 
         of Special Needs Boy Scout Troop 528 Dubai. Check out our <a href="https://www.troopwebhost.org/troop528dubai/" target = "_blank" > website</a>!</h1> <br/>
      <h1>I love coding, and have been programming since High School (2011). 
         I just love the fact that you can build websites and programs right from your couch, and delpoy them 
         for the world to see! I have developed an affinity towards problem solving and learning new things 
         during my years of coding and I'm interested in AI and Data science. I am currently learning machine 
         learning in my free time. </h1> <br />
      <h1>When I'm not devloping software or on Codewars solving coding challenges, I love being in the 
         outdoors. I've always enjoyed nature, and being in scouts since I was a kid, I've gone on many hikes 
         and campouts over the years. I'm also heavily involved in the special needs community in Dubai through my boy scout troop.   
         </h1> <br/>
         <h1>Check out the rest of my site, and feel free to contact me through github or linkedin!</h1>
    </div>
  }
}
  
export default About;